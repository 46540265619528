import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ChatService } from '../../services/chat-service.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DarkModeService } from 'src/app/services/dark-mode.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ProjectRole } from 'src/app/classes/enum/role';
import { ProjectsDataService } from 'src/app/services/projectsData.service';
import { Context } from 'src/app/interfaces/message';
import { AiServices } from 'src/app/services/ai-services.service';

@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.scss']
})
export class UserInputComponent implements OnInit {
  @Input() placeholder;
  @Output() userSendMessage = new EventEmitter()
  public messageValidate: boolean = true;
  public messageSend: boolean = false;
  public formData: FormGroup = new FormGroup({});
  public arrFormBulder: any = [];
  public activeChat: any;
  public innerMObile: boolean = false;
  public ModelHelp: boolean = false;
  projectRole: string | null;
  ProjectRole = ProjectRole;
  projectData: any;
  context!: Context
  role: ProjectRole;
  hebrew : boolean = false
  @ViewChild('toggleButtonHelp') toggleButtonHelp!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;


  constructor(private aiServices: AiServices, private projectDataService: ProjectsDataService, private chatService: ChatService, private fb: FormBuilder, private darkModeService: DarkModeService, private renderer: Renderer2, private firebaseService: FirebaseService, private router: Router) {
    this.projectRole = localStorage.getItem('rolesDisplay');
    if (this.projectRole && Object.values(ProjectRole).includes(this.projectRole as ProjectRole))
      this.role = this.projectRole as ProjectRole;
    this.projectData = this.projectDataService.findProjectByProp('rolesDisplay', this.projectRole)
    this.arrFormBulder["userInput"] = [""];
    this.formData = this.fb.group(this.arrFormBulder);
    this.chatService.messageStopWriten.subscribe((val: boolean) => {
      this.messageSend = false;
    });
    this.chatService.activeChatId.subscribe((res: any) => {
      console.log("user-in -cc - res", res)
      res.id != -1 ? this.activeChat = res.id : this.activeChat = ""
      res.suggestion ? this.context = res.suggestion : null
    })
    this.chatService.stopWritenMassage.subscribe(() => {
      this.messageSend = false
    })
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleButtonHelp.nativeElement) {
        this.ModelHelp = false;
      }
    });
    this.chatService.messageReceived.subscribe(messageSend => {
      this.messageSend = true;
    })
  }


  ngOnInit() {
    this.innerMObile = window.innerWidth <= 1200;
    this.hebrew = this.chatService.isHebrewChat() 
  }

  get isDarkMode() {
    return this.darkModeService.isDarkMode && this.router.url != '/';
  }

  sendUserInput(): void {
    if (this.formData.controls['userInput'].value.trim() !== '' && !this.messageSend) {
      console.log('this.activeChat befor send', this.activeChat)
      this.chatService.hideSuggestion.emit();
      this.userSendMessage.emit(this.formData.controls['userInput'].value);
      this.clearInput();
      this.firebaseService.logEvent('send_openAI');
    }
  }
  clearInput(): void {
    this.formData.controls['userInput'].setValue('');
    let element: HTMLElement | null = document.querySelector("textarea[formControlName='userInput']");
    element ? element.style.height = "24px" : "";
    this.messageValidate = true;
    this.messageSend = true;
    // // after 1 second to remove "STOP GENERATING text btn
    // setTimeout(() => {
    //   this.messageSend = false;
    // }, 1000);
  }
  autoGrowTextZone(e: any) {
    this.messageValidate = e.target.value && !this.messageSend ? false : true;
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight) + "px";
    parseInt(e.target.style.height) > 200 ? e.target.style.overflowY = "scroll" : e.target.style.overflowY = "hidden"
  }
  stopWrit() {
    this.chatService.messageStopWritenBTN()
  }
  openModelHelp() {
    this.ModelHelp = !this.ModelHelp;
  }
  openHelp() {
    window.open(environment.helpUrl, "_blank");
  }
  openPrivacy() {
    window.open(environment.privacyUrl, "_blank");
  }
  handleClick() {
    if (this.chatService.conversationId) {
      this.fileInput.nativeElement.click(); 
    } else {
      this.chatService.setError.emit('You cannot upload a file to an empty conversation')
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        console.log('start read file')
        const base64File = fileReader.result?.toString().split(',')[1]; // ממיר את הקובץ ל-Base64
        const params = {
          fileName: file.name,
          fileContent: base64File, 
          contentType: file.type
        };
        this.chatService.uploadFile(params, 'message/uploadFile/')
        this.fileInput.nativeElement.value = '';
      };
      fileReader.readAsDataURL(file);
      fileReader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    }
  }
}
